<template>
  <v-row no-gutters>
    <v-col>
      <div v-if="status" id="data-list">
        <!-- app drawer -->

        <v-snackbars
          :objects.sync="snackbar"
          width="500"
          :timeout="1500"
          bottom
          right
          color="error"
          transition="slide-y-transition"
        >
          <template v-slot:default="{ message }">
            <div v-if="message.status">
              <div class="text-body-1 font-weight-bold">
                {{ message.product }}
              </div>
              <div>
                Ürününden <strong> {{ message.count }} {{ message.birim }}</strong>
                <v-chip :color="message.color" label class="ml-1" small> {{ message.plant }} Fabrikasından </v-chip>
                sepetinize eklendi
              </div>
            </div>
            <div v-else>
              <div class="text-body-1 font-weight-bold">
                {{ message.product }}
              </div>
              <div>
                <strong> Sepetten çıkartıldı</strong>
              </div>
            </div>
          </template>
          <template v-slot:action="{ close }">
            <v-btn icon small @click="close()">
              <v-icon small>
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </template>
        </v-snackbars>

        <!--  <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" /> -->

        <customer-list v-if="(userData.role === 'PLSYR' || userData.role === 'FIN') && $vuetify.breakpoint.xsOnly" />

        <v-card
          tile
          :flat="$vuetify.breakpoint.mdAndDown"
          :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-tl-lg'"
          class="overflow-hidden"
        >
          <v-sheet
            v-if="$vuetify.breakpoint.xsOnly"
            :class="userData.role === 'PLSYR' || userData.role === 'FIN' ? 'search-mobil-plasiyer' : 'search-mobil'"
            color="transparent"
          >
            <v-text-field
              v-model="search"
              background-color="nightDark"
              solo
              :prepend-inner-icon="icons.mdiMagnify"
              label=""
              clearable
              hide-details
              class="rounded-0"
            >
              <template v-slot:label>
                <div class="text-caption">Aradığınız ürünü yazın</div>
              </template>
            </v-text-field>
          </v-sheet>

          <!-- table -->
          <v-sheet :style="$vuetify.breakpoint.xsOnly ? 'padding-top:50px' : null">
            <v-data-table
              :headers="tableColumns"
              :items="dataListTable"
              :search="search"
              :items-per-page="15"
              fixed-header
              :height="dataTableHeight"
              :loading="loading"
            >
              <template v-slot:header.product_code="{ header }">
                <div>
                  <v-text-field
                    v-model="product_code"
                    hide-details
                    background-color="grey"
                    class="rounded-0"
                    :prepend-icon="icons.mdiMagnify"
                    label="Kod"
                  ></v-text-field>
                </div>
              </template>

              <template v-slot:header.product_title="{ header }">
                <div>
                  <v-text-field
                    v-model="product_title"
                    hide-details
                    background-color="grey"
                    :prepend-icon="icons.mdiMagnify"
                    label="Ürün Adı "
                  ></v-text-field>
                </div>
              </template>

              <template #[`item.fiyat`]="{ item }">
                <span class="font-weight-bold"> {{ item.product_price_01 | currency }}</span>
              </template>

              <template v-slot:header.birim_ritim="{ header }">
                <div style="width: 75px">Koli İçi Adet</div>
              </template>

              <template #[`item.product_code`]="{ item }">
                <v-chip small label color="chip">
                  <span> {{ item.product_code }}</span>
                </v-chip>
              </template>

              <template #[`item.pictures`]="{ item }">
                <v-tooltip v-if="item.pictures" right color="white">
                  <template v-slot:activator="{ on, attrs }">
                    <v-card
                      v-bind="attrs"
                      tile
                      class="rounded d-flex align-center justify-center"
                      :width="!$vuetify.breakpoint.xsOnly ? 40 : 60"
                      :height="!$vuetify.breakpoint.xsOnly ? 40 : 60"
                      :color="item.pictures ? 'white' : 'primary'"
                      v-on="on"
                    >
                      <v-img
                        v-if="item.pictures"
                        :width="!$vuetify.breakpoint.xsOnly ? 30 : 50"
                        :height="!$vuetify.breakpoint.xsOnly ? 30 : 50"
                        :src="'https://portalapi.cetinplastik.com/' + item.pictures"
                      ></v-img>
                      <v-icon v-else>
                        {{ icons.mdiCameraOff }}
                      </v-icon>
                    </v-card>
                  </template>
                  <v-img width="250" height="250" :src="'https://portalapi.cetinplastik.com/' + item.pictures"></v-img>
                </v-tooltip>
                <v-card
                  v-else
                  tile
                  class="rounded d-flex align-center justify-center"
                  :width="!$vuetify.breakpoint.xsOnly ? 40 : 60"
                  :height="!$vuetify.breakpoint.xsOnly ? 40 : 60"
                  :color="item.pictures ? 'white' : 'primary'"
                >
                  <v-img
                    v-if="item.pictures"
                    :width="!$vuetify.breakpoint.xsOnly ? 30 : 50"
                    :height="!$vuetify.breakpoint.xsOnly ? 30 : 50"
                    :src="'https://portalapi.cetinplastik.com/' + item.pictures"
                  ></v-img>
                  <v-icon v-else>
                    {{ icons.mdiCameraOff }}
                  </v-icon>
                </v-card>
              </template>

              <template #[`item.miktar`]="{ item }">
                <Counter
                  :uid="item.id"
                  :birim="item.birim"
                  :birim-ritim="item.birim_ritim"
                  :miktar="item.product_pic_01"
                  plant-id="01"
                  bg-color="transparent elevation-0"
                  button-color="white"
                  :dark="true"
                  :light="false"
                  :status="false"
                  :filled="false"
                  :solo="true"
                  :item="item"
                ></Counter>
              </template>
            </v-data-table>
          </v-sheet>
        </v-card>
      </div>

      <div v-else>
        <!-- <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" /> -->
        <customer-list v-if="(userData.role === 'PLSYR' || userData.role === 'FIN') && $vuetify.breakpoint.xsOnly" />
        <v-alert
          v-if="!$store.state.plasiyerCustomer"
          text
          outlined
          class="mx-3 mx-lg-0 rounded-pill mt-2 mt-md-0 text-caption"
          type="warning"
        >
          Lütfen yukarıdan bir müşteri seçin
        </v-alert>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Counter from '@/components/Counter.vue'
import CustomerList from '@/components/CustomerList.vue'
import store from '@/store'
import { useRouter } from '@core/utils'
import {
  mdiArrowLeft,
  mdiCamera,
  mdiCameraOff,
  mdiClose,
  mdiDelete,
  mdiEye,
  mdiFilter,
  mdiFilterOff,
  mdiFlash,
  mdiMagnify,
  mdiPlusThick,
  mdiSquareEditOutline,
} from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'
import VSnackbars from 'v-snackbars'
import Vue from 'vue'
import orderStoreModule from '../orderStoreModule'

const userData = JSON.parse(localStorage.getItem('userData'))

export default {
  components: {
    Counter,
    VSnackbars,
    CustomerList,
  },
  setup() {
    const ORDER_APP_STORE_MODULE_NAME = 'app-order'

    const isDialogVisible = ref(false)

    const { router } = useRouter()

    // Register module
    if (!store.hasModule(ORDER_APP_STORE_MODULE_NAME)) {
      store.registerModule(ORDER_APP_STORE_MODULE_NAME, orderStoreModule)
    }

    // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(ORDER_APP_STORE_MODULE_NAME)) store.unregisterModule(ORDER_APP_STORE_MODULE_NAME)
    // })
    const dataListTable = ref([])
    const dataItem = ref({})
    const snackbar = ref([])
    const search = ref('')
    const product_title = ref('')
    const product_code = ref('')
    const status = ref(false)
    const koliAdet = ref('')
    const anlıkdeger = ref([])
    const toplam = ref(0)

    const koliEkle = () => {
      toplam.value = koliAdet.value * anlıkdeger.value.birim_ritim
      const uid1 = anlıkdeger.value.id

      store.commit('KOLI_TOPLAM', koliAdet.value)
      store.commit('KOLI_UID', uid1)
      store.commit('KOLI_EKLENDI', true)

      isDialogVisible.value = false
    }

    const openEkleDialog = item => {
      console.log(item.id)
      isDialogVisible.value = true
      anlıkdeger.value = item
    }

    watch(
      () => store.state.openKoli,
      () => {
        if (store.state.openKoli == true) {
          isDialogVisible.value = true
        } else {
          isDialogVisible.value = false
        }
      },
    )

    const tableColumns = [
      {
        text: 'Resim',
        value: 'pictures',
        width: '40px',
        sortable: false,
      },
      {
        text: 'Kod',
        value: 'product_code',
        width: '150px',
        sortable: false,
        filter: value => {
          if (!product_code.value) return true

          return value.toString().toLocaleUpperCase('TR').indexOf(product_code.value.toLocaleUpperCase('TR')) !== -1
        },
      },
      {
        text: 'Stok Tipi',
        value: 'mattype',
        width: '140px',
        sortable: false,
      },
      {
        text: 'Ürün Adı 1',
        value: 'product_title',
        sortable: false,
        class: 'min-width-400',
        filter: value => {
          if (!product_title.value) return true

          return value.toString().toLocaleUpperCase('TR').indexOf(product_title.value.toLocaleUpperCase('TR')) !== -1
        },
      },
      {
        text: 'Koli İçi Adet',
        value: 'birim_ritim',
        width: '60px',
        sortable: false,
        align: 'center',
      },

      {
        text: 'Fiyat',
        value: 'fiyat',
        align: 'center',
        width: '150px',
        sortable: false,
      },
      {
        text: 'Miktar',
        value: 'miktar',
        width: '250px',
        align: 'center',
        sortable: false,
      },
    ]
    if (window.screen.availWidth <= 600) {
      tableColumns.forEach(item => {
        delete item.filter
      })
    }
    const statusCustomer = ref(false)

    onMounted(() => {
      if (store.state.secondState == true && store.state.secondMsg) {
        Vue.swal({
          title: 'Hata',
          html: store.state.secondMsg,
          icon: 'error',
          background: '#FF4C51',
          timerProgressBar: true,
          showConfirmButton: false,
        })
        router.push({ name: 'payment' })
      }

      if (userData.role === 'PLSYR' && store.state.plasiyerCustomer === null) {
        status.value = false
      } else {
        status.value = true
        loading.value = true
        statusCustomer.value = true
        fetchBasket()
        fetchDatas()
      }

      // if (userData.role == 'PLSYR' && userData.uname != 'plasiyer') {
      //   router.push({ name: 'dashboard' })
      // }
    })

    watch(
      () => store.state.plasiyerCustomer,
      () => {
        console.log(`x = ${store.state.secondState}`)
        if (store.state.secondState == true) {
          Vue.swal({
            title: 'Hata',
            text: 'Kullanıcı değişti. Sadece ödeme yapılabilir',
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
          dataListTable.value = []
        }
        loading.value = true
        statusCustomer.value = false
        fetchBasket()
        fetchDatas()
        status.value = true

        // fetchDatas()
        // if (userData.role == 'PLSYR' && userData.uname != 'plasiyer') {
        //   router.push({ name: 'dashboard' })
        // }
      },
    )

    const totalDataListTable = ref(0)
    const loading = ref(false)
    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
    })
    const dataTotalLocal = ref([])
    const count = ref(1)

    /*
    watch(
      () => store.getters['app-order/doneBasket'],
      () => {
        fetchDatas(store.getters['app-order/doneBasket'])

        // fetchBasket()
        // refreshBasket()
      },
    )
    */

    const fetchBasket = async () => {
      await store.dispatch('app-order/fetchActiveBasketList').then(response => {
        if (!response.error) {
        } else {
          // console.table('hatalı =>', response)
        }
      })
    }
    const fetchDatas = async basketList => {
      const id = 0
      const page = 1
      const count = 6
      const plant = '01'
      const method = 'getProductsByCtgId'
      const delivery_date = store.state['app-order'].deliveryDate

      await store
        .dispatch('postMethod', {
          method,
          id,
          page,
          count,
          plant,
          delivery_date,
        })
        .then(response => {
          const productList = response.detail.products.map(item => {
            const items = {}
            items.id = item.id
            items.product_code = item.urun_kodu
            items.mattype = item.mattype
            items.product_title = item.baslik
            items.birim_ritim = Number(item.birim_ritmi)
            items.pictures = item.resim
            items.product_01 = true
            items.product_02 = true
            items.priceAll = item.priceAll
            if (item.priceAll.length > 0) {
              const price_01 = item.priceAll.find(i => i.plant === '01')
              const price_02 = item.priceAll.find(i => i.plant === '02')
              if (price_01) {
                items.product_price_01 = price_01?.price1
              }
              if (price_02) {
                items.product_price_02 = price_02?.price1
              }

              /*
              if (userData.role === 'PLSYR' && kondisyonDeger.value != '003') {
                item.priceAll.forEach(el => {
                  if (el.paymcond == paymcond) {
                    if (el.plant == '01') {
                      items.product_price_01 = el.price1
                    } else if (el.plant == '02') {
                      items.product_price_02 = el.price1
                    }
                  }

                })
              } else {

              }
              */
            }
            if (typeof items.product_price_01 === 'undefined') {
              items.product_01 = false
            }
            if (typeof items.product_price_02 === 'undefined') {
              items.product_02 = false
            }

            return items
          })

          dataListTable.value = productList
          console.log(dataListTable)
          totalDataListTable.value = Number(response.detail.toplam)
          loading.value = false
          if (store.state.secondState == true) {
            dataListTable.value = []
          }
        })
        .catch(e => {
          loading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
    }
    const statusFind = (arr, value, select) => store.state.tanimlar[arr].find(item => item.value === value)[select]

    return {
      statusFind,
      userData,
      dataItem,
      dataListTable,
      tableColumns,
      koliAdet,
      totalDataListTable,
      loading,
      options,
      dataTotalLocal,
      koliEkle,
      toplam,
      fetchDatas,
      count,
      snackbar,
      search,
      product_title,
      product_code,
      openEkleDialog,
      status,
      statusCustomer,
      isDialogVisible,
      anlıkdeger,

      // icons
      icons: {
        mdiCamera,
        mdiDelete,
        mdiCameraOff,
        mdiFlash,
        mdiEye,
        mdiFilter,
        mdiFilterOff,
        mdiPlusThick,
        mdiSquareEditOutline,
        mdiClose,
        mdiMagnify,
        mdiArrowLeft,
      },
    }
  },
  data() {
    return {
      panel: null,
      date: null,
      modal: false,
      filterStatus: false,
      dataTableHeight: '',
      height: {
        system: 0,
        top: this.$vuetify.breakpoint.smAndDown ? 0 : 0,
        footer: this.$vuetify.breakpoint.smAndDown ? 0 : 0,
      },
    }
  },

  watch: {
    panel(val) {
      if (val === 0) {
        setTimeout(() => {
          this.height.top = 250
          this.onResize()
        }, 300)
      } else {
        this.height.top = 48
        this.onResize()
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    addBasket(plant, product, id, count, birim) {
      store
        .dispatch('app-order/fetchAddToBasket', {
          method: 'addToBasket',
          uid: id,
          qty: count,
          plant,
        })
        .then(response => {
          if (response.error) {
            Vue.swal({
              title: 'Hata',
              html: response.detail.msg,
              icon: 'error',
              timer: 2000,
              background: '#FF4C51',

              timerProgressBar: true,
              showConfirmButton: false,
            })
          } else {
            this.snackbar.push({ product, count, birim })
          }
        })
    },
    async addToBasket(plants, product, id, count, birim) {
      if (plants.length === 1) {
        this.addBasket(plants[0].plant, product, id, count, birim)

        return
      }

      const plantsMap = new Map(plants.map(object => [object.plant, object.stext]))

      const { value: plant } = await Vue.swal({
        icon: 'info',
        title: 'Fabrika Seçimi',
        text: 'Lütfen ürünü eklemek istediğiniz fabrikayı seçiniz',
        input: 'select',
        inputOptions: plantsMap,
      })

      if (plant) {
        this.addBasket(plant, product, id, count, birim)
      }
    },
    onResize() {
      const val = this.$vuetify.breakpoint.xsOnly ? 120 : 90
      this.dataTableHeight = window.innerHeight - val - this.height.top - this.height.footer - 1
    },
  },
}
</script>

<style lang="scss">
@import '~@core/layouts/styles/_variables';
.search-mobil {
  position: fixed;
  z-index: 5;
  width: 100%;
  left: 0;
  top: 70px;
}

.search-mobil-plasiyer {
  position: fixed;
  z-index: 5;
  width: 100%;
  left: 0;
  top: 135px !important;
}
.text-plain {
  font-size: 10px;
  line-height: 1;
  padding-bottom: 2px;
}
.po-0 {
  .v-toolbar__content {
    padding: 0px 18px 0px 15px !important;
  }
}
// .v-data-table__wrapper::-webkit-scrollbar {
//   width: 8px;
//   height: 8px;
//   background-color: #3b355a;
// }
// .v-data-table__wrapper::-webkit-scrollbar-thumb {
//   background-color: #0083e8;
// }
// .v-application.theme--dark .app-content-container .v-data-table-header .plant_konya {
//   border-bottom: 1px solid #ffb400 !important ;
// }
// .v-application.theme--dark .app-content-container .v-data-table-header .plant_tekirdag {
//   border-bottom: 1px solid #0083e8 !important ;
// }

.min-width-400 {
  min-width: 360px;
}
.style-1 {
  background-color: rgba(255, 255, 255, 0.05);
}
.style-2 {
  // background-color: rgb(114, 114, 67);
}
/*

@media #{map-get($display-breakpoints, 'xs-only')} {
  .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
    min-height: auto !important;
  }
  .theme--light tr {
    background-color: #fff !important;
  }
  .theme--dark tr {
    background-color: #3b355a !important;
  }
  .v-data-table__empty-wrapper {
    td {
      position: relative !important;
    }
  }
  tr {
    position: relative;
    padding: 15px 0;

    border-bottom: thin solid rgba(231, 227, 252, 0.14);

    td:nth-child(1) {
      position: absolute;

      .v-data-table__mobile-row__header {
        display: none;
      }
    }
    td:nth-child(2),
    td:nth-child(3) {
      padding-top: 5px !important;
      .v-data-table__mobile-row__header {
        display: none;
      }
      .v-data-table__mobile-row__cell {
        padding-left: 70px;
        text-align: left !important;
      }
    }
    td:nth-child(4) {
      display: flex;
      justify-content: flex-end;
      padding-top: 30px !important;
      .v-data-table__mobile-row__header {
        display: none;
      }
    }
    td:nth-child(5) {
      display: flex;
      justify-content: flex-end;
      .v-data-table__mobile-row__header {
        display: none;
      }
    }
  }
  .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:last-child,
  .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:last-child {
    border: none !important;
  }
}
*/
.v-tooltip__content {
  opacity: 1 !important;
  box-shadow: 5px 5px 10px 0px rgb(0 0 0 / 30%) !important;
}

.rounded-kor {
  .v-input__slot {
    padding: 0 !important;
    padding-right: 5px !important;
    padding-left: 10px !important;
  }
}
</style>
